$path_to_icons: '../icons/svg/';
@import '../../../../../scss/tools';

// -webkit-mask here and in other places added to support Chromium
// at some point autoprefixer was broken
// and it does not add `-webkit` prefix to `mask` property anymore
// remove prefix when we will stop support Chromium
// or remove prefix when autoprefixer will fix the issue

i.icon {
  background-color: white;
  display: block;
  // todo CHAPTER GP-18685 at the moment the min size is 12 per design
  min-width: 15px;
  min-height: 15px;

  &-arrow-right {
    -webkit-mask: url($path_to_icons + 'arrow-m.svg') no-repeat 50% 50%;
    min-width: 21px;
    min-height: 20px;
    transform: rotate(180deg);
  }

  &-arrow-corner-up-right {
    -webkit-mask: url($path_to_icons + 'arrow-corner-up-right.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-arrow-corner-top-left {
    -webkit-mask: url($path_to_icons + 'arrow-corner-top-left.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-arrow-rotate-left {
    &-64 {
      -webkit-mask: url($path_to_icons + 'arrow-rotate-left-64.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-asterisk {
    -webkit-mask: url($path_to_icons + 'asterisk.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-asterisk-sm {
    -webkit-mask: url($path_to_icons + 'asterisk-sm.svg') no-repeat 50% 50%;
    min-width: 8px;
    min-height: 8px;
  }

  &-arrow-link {
    -webkit-mask: url($path_to_icons + 'arrow-link.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 20px;
  }

  &-arrow {
    &-m {
      -webkit-mask: url($path_to_icons + 'arrow-m.svg') no-repeat 50% 50%;
      min-width: 21px;
      min-height: 20px;
    }

    &-lg {
      -webkit-mask: url($path_to_icons + 'arrow-lg.svg') no-repeat 50% 50%;
      min-width: 60px;
      min-height: 35px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'arrow-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-avatar {
    &-32 {
      -webkit-mask: url($path_to_icons + 'avatar-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-award {
    -webkit-mask: url($path_to_icons + 'award.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
    &-sm {
      -webkit-mask: url($path_to_icons + 'award-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-at {
    -webkit-mask: url($path_to_icons + 'at.svg') no-repeat 50% 50%;
    min-width: 60px;
    min-height: 60px;
  }

  &-block {
    &-16 {
      -webkit-mask: url($path_to_icons + 'block.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-24 {
      -webkit-mask: url($path_to_icons + 'block.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
    &-64 {
      -webkit-mask: url($path_to_icons + 'block.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-copy {
    -webkit-mask: url($path_to_icons + 'copy.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-24 {
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-home {
    &-lg {
      -webkit-mask: url($path_to_icons + 'home-lg.svg') no-repeat 50% 50%;
      min-width: 20px;
      min-height: 22px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'home-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-search {
    -webkit-mask: url($path_to_icons + 'search.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-32 {
      -webkit-mask: url($path_to_icons + 'search-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }

    &-24 {
      -webkit-mask: url($path_to_icons + 'search-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-20 {
      -webkit-mask: url($path_to_icons + 'search-20.svg') no-repeat 50% 50%;
      min-width: 20px;
      min-height: 20px;
    }
  }

  &-menu {
    -webkit-mask: url($path_to_icons + 'menu-options.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &.icon-users {
    -webkit-mask: url($path_to_icons + 'users.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &.icon-user-remove {
    -webkit-mask: url($path_to_icons + 'user-remove-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 14px;
  }

  &.icon-shape {
    -webkit-mask: url($path_to_icons + 'shape.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-cog {
    -webkit-mask: url($path_to_icons + 'cog.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-angle-left {
    -webkit-mask: url($path_to_icons + 'angle-left.svg') no-repeat 50% 50%;
    min-width: 9px;
    min-height: 16px;
  }

  &-angle-right {
    -webkit-mask: url($path_to_icons + 'angle-left.svg') no-repeat 50% 50%;
    min-width: 9px;
    min-height: 16px;
    transform: rotate(180deg);
  }

  &-angle-down-sm {
    -webkit-mask: url($path_to_icons + 'angle-down-sm.svg') no-repeat 50% 50%;
    min-width: 10px;
    min-height: 6px;
  }

  &-play {
    -webkit-mask: url($path_to_icons + 'play.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'play-sm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }

    &-xs {
      -webkit-mask: url($path_to_icons + 'play-sm.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 10px;
    }
  }

  &-apple {
    -webkit-mask: url($path_to_icons + 'brand-apple.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-md {
      -webkit-mask: url($path_to_icons + 'brand-apple-md.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'brand-apple-sm.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 10px;
    }

    &-pack {
      -webkit-mask: url($path_to_icons + 'brand-apple-pack.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-windows {
    -webkit-mask: url($path_to_icons + 'brand-windows.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-md {
      -webkit-mask: url($path_to_icons + 'brand-windows-md.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'brand-windows-sm.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 10px;
    }

    &-pack {
      -webkit-mask: url($path_to_icons + 'brand-windows-pack.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-linux {
    -webkit-mask: url($path_to_icons + 'brand-linux.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 14px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'brand-linux-sm.svg') no-repeat 50% 50%;
      min-width: 9px;
      min-height: 10px;
    }

    &-pack {
      -webkit-mask: url($path_to_icons + 'brand-linux-pack.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-brand-ultra {
    -webkit-mask: url($path_to_icons + 'brand-ultra.svg') no-repeat 50% 50%;
    min-width: 40px;
    min-height: 40px;
    mask-size: 40px 40px;
  }

  &-broken-image {
    &-24 {
      -webkit-mask: url($path_to_icons + 'broken-image-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-32 {
      -webkit-mask: url($path_to_icons + 'broken-image-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-circle {
    -webkit-mask: url($path_to_icons + 'circle.svg') no-repeat 50% 50%;
    min-width: 12px;
    min-height: 12px;
    mask-size: 12px 12px;
  }

  &-circle-slash {
    -webkit-mask: url($path_to_icons + 'circle-slash.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-star {
    -webkit-mask: url($path_to_icons + 'star.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 19px;

    &-16 {
      -webkit-mask: url($path_to_icons + 'star-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-md {
      -webkit-mask: url($path_to_icons + 'star-md.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }

    &-filled {
      -webkit-mask: url($path_to_icons + 'star-filled.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;

      &-16 {
        -webkit-mask: url($path_to_icons + 'star-filled-16.svg') no-repeat 50% 50%;
        min-width: 16px;
        min-height: 16px;
      }

      &-sm {
        -webkit-mask: url($path_to_icons + 'star-filled-sm.svg') no-repeat 50% 50%;
        min-width: 10px;
        min-height: 10px;
      }

      &-md {
        -webkit-mask: url($path_to_icons + 'star-filled-md.svg') no-repeat 50% 50%;
        min-width: 14px;
        min-height: 14px;
      }
    }

    &-stroke {
      -webkit-mask: url($path_to_icons + 'star-stroke.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-outline {
      -webkit-mask: url($path_to_icons + 'star-outline.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-empty-star {
    -webkit-mask: url($path_to_icons + 'star-empty.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-check-star {
    -webkit-mask: url($path_to_icons + 'star-check.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 32px;
  }

  &-check-default {
    -webkit-mask: url($path_to_icons + 'check-default.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'check-default-sm.svg') no-repeat 50% 50%;
      min-width: 12px;
      min-height: 12px;
    }
  }

  &-person {
    -webkit-mask: url($path_to_icons + 'person.svg') no-repeat 50% 50%;
    min-width: 17px;
    min-height: 16px;
  }

  &-person-flat {
    -webkit-mask: url($path_to_icons + 'person-flat.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-person-couple {
    -webkit-mask: url($path_to_icons + 'person-couple.svg') no-repeat 50% 50%;
    min-width: 17px;
    min-height: 16px;
  }

  &-user-group {
    &-sm {
      -webkit-mask: url($path_to_icons + 'user-group-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-large {
      -webkit-mask: url($path_to_icons + 'user-group-large.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }

    &-lg {
      -webkit-mask: url($path_to_icons + 'user-group-lg.svg') no-repeat 50% 50%;
      min-width: 17px;
      min-height: 16px;
    }

    &-circle {
      -webkit-mask: url($path_to_icons + 'user-group-circle.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-user {
    &-sm {
      -webkit-mask: url($path_to_icons + 'user-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-lg {
      -webkit-mask: url($path_to_icons + 'user.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-admin {
    &-sm {
      -webkit-mask: url($path_to_icons + 'admin-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-person-group-flat {
    -webkit-mask: url($path_to_icons + 'person-group-flat.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-person-group-circle-flat {
    -webkit-mask: url($path_to_icons + 'person-group-circle-flat.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-sword {
    -webkit-mask: url($path_to_icons + 'sword.svg') no-repeat 50% 50%;
    min-width: 14px;
    min-height: 14px;
  }

  &-comment {
    -webkit-mask: url($path_to_icons + 'comment.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &[class*='icon-triangle-sm'] {
    -webkit-mask: url($path_to_icons + 'triangle-sm.svg') no-repeat 50% 50%;
    min-width: 8px;
    min-height: 5px;
  }

  &[class*='icon-triangle-lg'] {
    -webkit-mask: url($path_to_icons + 'triangle-lg.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-triangle-sm-top,
  &-triangle-lg-top {
    -webkit-mask: url($path_to_icons + 'triangle-lg.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
    transform: rotate(180deg);
  }

  &-triangle-sm-left,
  &-triangle-lg-left {
    transform: rotate(90deg);
  }

  &-core-build-win {
    -webkit-mask: url($path_to_icons + 'core-build-win.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-core-build-mac {
    -webkit-mask: url($path_to_icons + 'core-build-mac.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }
  &-compatible {
    -webkit-mask: url($path_to_icons + 'compatible.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-triangle-sm-right,
  &-triangle-lg-right {
    transform: rotate(-90deg);
  }

  &-triangle-play {
    -webkit-mask: url($path_to_icons + 'triangle-play.svg') no-repeat 50% 50%;
    min-width: 14px;
    min-height: 18px;
  }

  &-triangle-border {
    &-lg {
      -webkit-mask: url($path_to_icons + 'triangle-border-lg.svg') no-repeat 50% 50%;
      min-width: 20px;
      min-height: 18px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'triangle-border-sm.svg') no-repeat 50% 50%;
      min-width: 11px;
      min-height: 14px;
    }
  }

  &-order {
    -webkit-mask: url($path_to_icons + 'order.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 14px;
  }

  &-cloud {
    -webkit-mask: url($path_to_icons + 'cloud.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 16px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'cloud-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-cloud-upload {
    -webkit-mask: url($path_to_icons + 'cloud-upload.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-clock {
    -webkit-mask: url($path_to_icons + 'clock.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'clock-sm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }
  }

  &-clock-check {
    &-16 {
      -webkit-mask: url($path_to_icons + 'clock-check-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-24 {
      -webkit-mask: url($path_to_icons + 'clock-check-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-joystick {
    -webkit-mask: url($path_to_icons + 'joystick.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 16px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'joystick-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-vr {
    -webkit-mask: url($path_to_icons + 'vr.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 12px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'vr-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-vr-cardboard {
    -webkit-mask: url($path_to_icons + 'vr-cardboard.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
    &-sm {
      -webkit-mask: url($path_to_icons + 'vr-cardboard-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-medal {
    -webkit-mask: url($path_to_icons + 'medal.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'medal-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-round-medal {
    -webkit-mask: url($path_to_icons + 'medal-round.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;
  }

  &-goblet {
    -webkit-mask: url($path_to_icons + 'goblet.svg') no-repeat 50% 50%;
    min-width: 18px;
    min-height: 18px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'goblet-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-chart {
    -webkit-mask: url($path_to_icons + 'chart.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'chart-lg.svg') no-repeat 50% 50%;
      min-width: 20px;
      min-height: 21px;
    }
  }

  &-cheat {
    -webkit-mask: url($path_to_icons + 'cheat.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'cheat-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-beta {
    -webkit-mask: url($path_to_icons + 'beta.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;

    &-xs {
      -webkit-mask: url($path_to_icons + 'beta-sm.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 12px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'beta-sm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }

    &-lg {
      -webkit-mask: url($path_to_icons + 'beta-lg.svg') no-repeat 50% 50%;
      min-width: 17px;
      min-height: 27px;
    }
  }

  &-code {
    -webkit-mask: url($path_to_icons + 'code.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-identity {
    -webkit-mask: url($path_to_icons + 'hug.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-hourglass {
    &-16 {
      -webkit-mask: url($path_to_icons + 'hourglass.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-24 {
      -webkit-mask: url($path_to_icons + 'hourglass.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
    &-32 {
      -webkit-mask: url($path_to_icons + 'hourglass.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
    &-64 {
      -webkit-mask: url($path_to_icons + 'hourglass.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-puzzle {
    -webkit-mask: url($path_to_icons + 'puzzle.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;
  }

  &-shopping-cart {
    -webkit-mask: url($path_to_icons + 'shopping-cart.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;
  }

  &-store {
    -webkit-mask: url($path_to_icons + 'store.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-shopping-bag {
    -webkit-mask: url($path_to_icons + 'shopping-bag.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'shopping-bag-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }

    &-150 {
      -webkit-mask: url($path_to_icons + 'shopping-bag-150.svg') no-repeat 50% 50%;
      min-width: 150px;
      min-height: 150px;
    }
  }

  &-cart {
    &-lg {
      -webkit-mask: url($path_to_icons + 'cart-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'cart-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-tradability {
    &-sm {
      @extend .icon-16x;
      -webkit-mask: url($path_to_icons + 'tradable-16.svg') no-repeat 50% 50%;
    }

    &-lg {
      @extend .icon-24x;
      -webkit-mask: url($path_to_icons + 'tradable-24.svg') no-repeat 50% 50%;
    }

    &-32 {
      @extend .icon-32x;
      -webkit-mask: url($path_to_icons + 'tradable-32.svg') no-repeat 50% 50%;
    }
  }

  &-not-tradability {
    &-sm {
      @extend .icon-16x;
      -webkit-mask: url($path_to_icons + 'tradable-not-16.svg') no-repeat 50% 50%;
    }

    &-lg {
      @extend .icon-24x;
      -webkit-mask: url($path_to_icons + 'tradable-not-24.svg') no-repeat 50% 50%;
    }
  }

  &-transferability {
    &-sm {
      @extend .icon-16x;
      -webkit-mask: url($path_to_icons + 'transferable-16.svg') no-repeat 50% 50%;
    }

    &-lg {
      @extend .icon-24x;
      -webkit-mask: url($path_to_icons + 'transferable-24.svg') no-repeat 50% 50%;
    }

    &-32 {
      @extend .icon-32x;
      -webkit-mask: url($path_to_icons + 'transferable-32.svg') no-repeat 50% 50%;
    }
  }

  &-not-transferability {
    &-sm {
      @extend .icon-16x;
      -webkit-mask: url($path_to_icons + 'transferable-not-16.svg') no-repeat 50% 50%;
    }

    &-lg {
      @extend .icon-24x;
      -webkit-mask: url($path_to_icons + 'transferable-not-24.svg') no-repeat 50% 50%;
    }
  }

  &-tradeof {
    -webkit-mask: url($path_to_icons + 'tradeof-20.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 21px;
  }

  &-transfer-crypto {
    -webkit-mask: url($path_to_icons + 'transfer-crypto.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-transfer-uos {
    -webkit-mask: url($path_to_icons + 'transfer-uos.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-transfer-uos-16 {
    -webkit-mask: url($path_to_icons + 'transfer-uos-16.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-transfer-uniq {
    -webkit-mask: url($path_to_icons + 'transfer-uniq.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-dollar {
    &-lg {
      -webkit-mask: url($path_to_icons + 'dollar-lg.svg') no-repeat 50% 50%;
      min-width: 20px;
      min-height: 21px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'dollar-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-sign {
      -webkit-mask: url($path_to_icons + 'dollar-sign.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-library {
    -webkit-mask: url($path_to_icons + 'library.svg') no-repeat 50% 50%;
    min-width: 18px;
    min-height: 17px;
  }

  &-share {
    -webkit-mask: url($path_to_icons + 'share.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'share-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-share-light {
    &-20 {
      -webkit-mask: url($path_to_icons + 'share-light.svg') no-repeat 50% 50%;
      min-width: 20px;
      min-height: 20px;
    }
  }

  &-flag {
    &-20 {
      -webkit-mask: url($path_to_icons + 'flag.svg') no-repeat 50% 50%;
      min-width: 20px;
      min-height: 20px;
    }
  }

  &-facebook {
    -webkit-mask: url($path_to_icons + 'facebook.svg') no-repeat 50% 50%;
    min-width: 8px;
    min-height: 14px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'facebook-lg.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 21px;
    }
  }

  &-twitter {
    -webkit-mask: url($path_to_icons + 'twitter.svg') no-repeat 50% 50%;
    min-width: 12px;
    min-height: 10px;
  }

  &-messenger {
    -webkit-mask: url($path_to_icons + 'messenger.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-telegram {
    -webkit-mask: url($path_to_icons + 'telegram.svg') no-repeat 50% 50%;
    min-width: 11px;
    min-height: 12px;
  }

  &-chain {
    -webkit-mask: url($path_to_icons + 'chain.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-gamepad {
    -webkit-mask: url($path_to_icons + 'gamepad.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 20px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'gamepad-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-lg {
      -webkit-mask: url($path_to_icons + 'gamepad-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
    &-16 {
      -webkit-mask: url($path_to_icons + 'gamepad-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-30 {
      -webkit-mask: url($path_to_icons + 'gamepad-lg.svg') no-repeat 50% 50%;
      min-width: 30px;
      min-height: 30px;
    }
    &-32 {
      -webkit-mask: url($path_to_icons + 'gamepad-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }
  &-podium {
    -webkit-mask: url($path_to_icons + 'podium-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-gamepad-light {
    -webkit-mask: url($path_to_icons + 'gamepad-light.svg') no-repeat 50% 50%;
    min-width: 64px;
    min-height: 64px;
  }

  &-gamepad-dlc-light {
    -webkit-mask: url($path_to_icons + 'gamepad-dlc-light.svg') no-repeat 50% 50%;
    min-width: 64px;
    min-height: 64px;
  }

  &-link-external {
    -webkit-mask: url($path_to_icons + 'link-external.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 20px;
  }

  &-bold-cross {
    -webkit-mask: url($path_to_icons + 'cross-bold.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-cross {
    &-16 {
      -webkit-mask: url($path_to_icons + 'cross-m.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'cross-sm.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 10px;
    }

    &-24 {
      -webkit-mask: url($path_to_icons + 'cross-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-32 {
      -webkit-mask: url($path_to_icons + 'cross-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }

    &-40 {
      -webkit-mask: url($path_to_icons + 'cross-40.svg') no-repeat 50% 50%;
      min-width: 40px;
      min-height: 40px;
    }

    &-64 {
      -webkit-mask: url($path_to_icons + 'cross-64.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-crypto-ether {
    -webkit-mask: url($path_to_icons + 'crypto-ether.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-edit {
    &-sm {
      -webkit-mask: url($path_to_icons + 'edit-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-xs {
      -webkit-mask: url($path_to_icons + 'edit-xs.svg') no-repeat 50% 50%;
      min-width: 12px;
      min-height: 12px;
    }
  }

  &-trash {
    &-lg {
      -webkit-mask: url($path_to_icons + 'trash-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'trash-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-12 {
      -webkit-mask: url($path_to_icons + 'trash2-12.svg') no-repeat 50% 50%;
      min-width: 12px;
      min-height: 12px;
    }

    &-16 {
      -webkit-mask: url($path_to_icons + 'trash2-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-32 {
      -webkit-mask: url($path_to_icons + 'trash2-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-question {
    -webkit-mask: url($path_to_icons + 'question.svg') no-repeat 50% 50%;
    min-width: 18px;
    min-height: 18px;
  }

  &-question-lg {
    -webkit-mask: url($path_to_icons + 'question-lg.svg') no-repeat 50% 50%;
    min-width: 18px;
    min-height: 18px;
  }

  &-launcher {
    -webkit-mask: url($path_to_icons + 'launcher.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-check {
    -webkit-mask: url($path_to_icons + 'check.svg') no-repeat 50% 50%;
    min-width: 18px;
    min-height: 18px;

    &-xs {
      -webkit-mask: url($path_to_icons + 'check-xs.svg') no-repeat 50% 50%;
      min-width: 7px;
      min-height: 6px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'check-sm.svg') no-repeat 50% 50%;
      min-width: 8px;
      min-height: 7px;
    }

    &-lg {
      -webkit-mask: url($path_to_icons + 'check-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-16 {
      -webkit-mask: url($path_to_icons + 'check-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-24 {
      -webkit-mask: url($path_to_icons + 'check-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-32 {
      -webkit-mask: url($path_to_icons + 'check-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }

    &-64 {
      -webkit-mask: url($path_to_icons + 'check-64.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-check-light {
    -webkit-mask: url($path_to_icons + 'check-light.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-small-check {
    -webkit-mask: url($path_to_icons + 'check-small.svg') no-repeat 50% 50%;
    min-width: 12px;
    min-height: 9px;
  }

  &-check-circle {
    -webkit-mask: url($path_to_icons + 'check-circle.svg') no-repeat 50% 50%;
    min-width: 48px;
    min-height: 48px;

    &-24 {
      background: url($path_to_icons + 'check-circle-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-48 {
      background: url($path_to_icons + 'check-circle-48.svg') no-repeat 50% 50%;
      min-width: 48px;
      min-height: 48px;
    }
  }

  &-check-circle-outline {
    -webkit-mask: url($path_to_icons + 'check-circle-outline.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-12 {
      -webkit-mask: url($path_to_icons + 'check-circle-outline-12.svg') no-repeat 50% 50%;
      min-width: 12px;
      min-height: 12px;
    }

    &-24 {
      -webkit-mask: url($path_to_icons + 'check-circle-outline-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-document {
    -webkit-mask: url($path_to_icons + 'document.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-ultra-sign {
    -webkit-mask: url($path_to_icons + 'ultra-sign.svg') no-repeat 50% 50%;
    min-width: 48px;
    min-height: 32px;
  }

  &-ultra-marketplace {
    -webkit-mask: url($path_to_icons + 'ultra-marketplace.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-os-name-windows {
    -webkit-mask: url($path_to_icons + 'brand-windows.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-os-name-macos {
    -webkit-mask: url($path_to_icons + 'apple.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-exist {
    -webkit-mask: url($path_to_icons + 'exist.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-os-name-osx {
    -webkit-mask: url($path_to_icons + 'apple.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-os-name-ubuntu {
    -webkit-mask: url($path_to_icons + 'linux.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-os-name-vr {
    -webkit-mask: url($path_to_icons + 'features-vr.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-file {
    -webkit-mask: url($path_to_icons + 'file.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'file-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-file-text {
    -webkit-mask: url($path_to_icons + 'file-text.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-file-encrypted {
    -webkit-mask: url($path_to_icons + 'file-encrypted.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-repeat {
    -webkit-mask: url($path_to_icons + 'repeat.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-trending-up {
    -webkit-mask: url($path_to_icons + 'trending-up.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-shield {
    -webkit-mask: url($path_to_icons + 'shield.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'shield-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-vertical-points {
    -webkit-mask: url($path_to_icons + 'vertical-points.svg') no-repeat 50% 50%;
    min-width: 6px;
    min-height: 19.2px;
  }

  &-download {
    &-sm {
      -webkit-mask: url($path_to_icons + 'download-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-m {
      -webkit-mask: url($path_to_icons + 'download-m.svg') no-repeat 50% 50%;
      min-width: 20.7px;
      min-height: 17.9px;
    }

    &-lg {
      -webkit-mask: url($path_to_icons + 'download-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-lp {
    -webkit-mask: url($path_to_icons + 'languages-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-languages {
    &-sm {
      -webkit-mask: url($path_to_icons + 'languages-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-dserver {
    -webkit-mask: url($path_to_icons + 'dserver-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'dserver-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-demo {
    -webkit-mask: url($path_to_icons + 'demo-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-upload {
    -webkit-mask: url($path_to_icons + 'upload.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-ign {
    -webkit-mask: url($path_to_icons + 'ign.svg') no-repeat 50% 50%;
    min-width: 40px;
    min-height: 40px;
  }

  &-intersect {
    -webkit-mask: url($path_to_icons + 'intersect.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-repair {
    -webkit-mask: url($path_to_icons + 'repair.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-uninstall {
    -webkit-mask: url($path_to_icons + 'uninstall.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-refresh {
    -webkit-mask: url($path_to_icons + 'refresh.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'refresh-lg.svg') no-repeat 50% 50%;
      min-width: 48px;
      min-height: 48px;
    }
  }

  &-refund {
    -webkit-mask: url($path_to_icons + 'refund.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-save {
    -webkit-mask: url($path_to_icons + 'save.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-placeholder-media {
    -webkit-mask: url($path_to_icons + 'placeholder-media.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'placeholder-media-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-logotype {
    -webkit-mask: url($path_to_icons + 'logotype.svg') no-repeat 50% 50%;
    min-width: 184px;
    min-height: 28px;
  }

  &-google-lg {
    -webkit-mask: url($path_to_icons + 'google-lg.svg') no-repeat 50% 50%;
    min-width: 20px;
    min-height: 20px;
  }

  &-smartphone {
    -webkit-mask: url($path_to_icons + 'smartphone.svg') no-repeat 50% 50%;
    min-width: 70px;
    min-height: 70px;
  }

  &-smartphone-sm {
    -webkit-mask: url($path_to_icons + 'smartphone-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-plus {
    &-lg {
      -webkit-mask: url($path_to_icons + 'plus-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-m {
      -webkit-mask: url($path_to_icons + 'plus-m.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-sm {
      -webkit-mask: url($path_to_icons + 'plus-sm.svg') no-repeat 50% 50%;
      min-width: 12px;
      min-height: 12px;
    }

    &-square {
      -webkit-mask: url($path_to_icons + 'plus-square.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-circle-32 {
      -webkit-mask: url($path_to_icons + 'plus-circle.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-chevron {
    -webkit-mask: url($path_to_icons + 'chevron.svg') no-repeat 50% 50%;
    min-width: 10px;
    min-height: 16px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'chevron-sm.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 6px;
    }

    &-16 {
      -webkit-mask: url($path_to_icons + 'chevron-16.svg') no-repeat 50% 50%;
      min-width: 10px;
      min-height: 16px;
    }

    &-left-24 {
      -webkit-mask: url($path_to_icons + 'chevron-left-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-right-16 {
      -webkit-mask: url($path_to_icons + 'chevron-right-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-right-24 {
      -webkit-mask: url($path_to_icons + 'chevron-right-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-down-16 {
      -webkit-mask: url($path_to_icons + 'chevron-down-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-down-24 {
      -webkit-mask: url($path_to_icons + 'chevron-down-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-chevron-sm-right {
    -webkit-mask: url($path_to_icons + 'chevron-sm.svg') no-repeat 50% 50%;
    min-width: 10px;
    min-height: 6px;
    transform: rotate(-90deg);
  }

  &-folder {
    -webkit-mask: url($path_to_icons + 'folder.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-filled {
      -webkit-mask: url($path_to_icons + 'folder-filled.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-eye {
    -webkit-mask: url($path_to_icons + 'eye.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'eye-lg.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }

    &-on {
      -webkit-mask: url($path_to_icons + 'eye-on.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-off {
      -webkit-mask: url($path_to_icons + 'eye-off.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-reload {
    -webkit-mask: url($path_to_icons + 'reload.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-setting {
    -webkit-mask: url($path_to_icons + 'setting.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-image {
    -webkit-mask: url($path_to_icons + 'image.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;

    &-add {
      -webkit-mask: url($path_to_icons + 'image-add.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-import {
    &-32 {
      -webkit-mask: url($path_to_icons + 'import-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-box {
    -webkit-mask: url($path_to_icons + 'box.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'box-lg.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-info {
    -webkit-mask: url($path_to_icons + 'info.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'info-sm.svg') no-repeat 50% 50%;
      min-width: 12px;
      min-height: 12px;
    }
  }

  &-action {
    -webkit-mask: url($path_to_icons + 'genre-action.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-adventure {
    -webkit-mask: url($path_to_icons + 'genre-adventure.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-flight {
    -webkit-mask: url($path_to_icons + 'genre-flight.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-fighting {
    -webkit-mask: url($path_to_icons + 'genre-fighting.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-fps {
    -webkit-mask: url($path_to_icons + 'genre-fps.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-indie {
    -webkit-mask: url($path_to_icons + 'genre-indie.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-puzzle {
    -webkit-mask: url($path_to_icons + 'genre-puzzle.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-racing {
    -webkit-mask: url($path_to_icons + 'genre-racing.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-rpg {
    -webkit-mask: url($path_to_icons + 'genre-rpg.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-simulation {
    -webkit-mask: url($path_to_icons + 'genre-simulation.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-sports {
    -webkit-mask: url($path_to_icons + 'genre-sports.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-strategy {
    -webkit-mask: url($path_to_icons + 'genre-strategy.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-dnd {
    -webkit-mask: url($path_to_icons + 'dnd.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-user {
    -webkit-mask: url($path_to_icons + 'user.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-two-person {
    -webkit-mask: url($path_to_icons + 'two-person.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;

    &-circle {
      -webkit-mask: url($path_to_icons + 'two-person-circle.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-warning {
    -webkit-mask: url($path_to_icons + 'warning.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-workshop {
    -webkit-mask: url($path_to_icons + 'workshop.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
    &-sm {
      min-width: 16px;
      min-height: 16px;
      -webkit-mask: url($path_to_icons + 'workshop-sm.svg') no-repeat 50% 50%;
    }
  }

  &-unlink {
    -webkit-mask: url($path_to_icons + 'unlink.svg') no-repeat 50% 50%;
    min-width: 31px;
    min-height: 31px;
  }

  &-zoom-in {
    &-lg {
      min-width: 24px;
      min-height: 24px;
      -webkit-mask: url($path_to_icons + 'zoom-in-lg.svg') no-repeat 50% 50%;
    }
  }

  &-zoom-out {
    &-lg {
      min-width: 24px;
      min-height: 24px;
      -webkit-mask: url($path_to_icons + 'zoom-out-lg.svg') no-repeat 50% 50%;
    }
  }

  &-comment {
    -webkit-mask: url($path_to_icons + 'comment.svg') no-repeat 50% 50%;
    min-width: 14px;
    min-height: 14px;
  }

  &-dev {
    -webkit-mask: url($path_to_icons + 'dev.svg') no-repeat 50% 50%;
    min-width: 14px;
    min-height: 14px;
  }

  &-branch {
    -webkit-mask: url($path_to_icons + 'branch.svg') no-repeat 50% 50%;
    min-width: 14px;
    min-height: 14px;
  }

  &-calendar {
    &-sm {
      -webkit-mask: url($path_to_icons + 'calendar-sm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }
  }

  &-limited-quantity {
    &-sm {
      -webkit-mask: url($path_to_icons + 'limited-quantity-sm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }
  }

  &-resell {
    &-32 {
      -webkit-mask: url($path_to_icons + 'resell-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
    &-sm {
      -webkit-mask: url($path_to_icons + 'resell-sm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }
  }

  &-marketplace {
    &-32 {
      -webkit-mask: url($path_to_icons + 'marketplace-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
    &-16 {
      -webkit-mask: url($path_to_icons + 'marketplace-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-marketplace-cancel {
    &-16 {
      -webkit-mask: url($path_to_icons + 'marketplace-cancel-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-more-vertical {
    -webkit-mask: url($path_to_icons + 'more-vertical.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-globe {
    -webkit-mask: url($path_to_icons + 'globe.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-alert {
    -webkit-mask: url($path_to_icons + 'alert.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-alert-triangle {
    &-16 {
      -webkit-mask: url($path_to_icons + 'alert-triangle-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-24 {
      -webkit-mask: url($path_to_icons + 'alert-triangle-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
    &-64 {
      -webkit-mask: url($path_to_icons + 'alert-triangle-64.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-alert-circle {
    &-48 {
      background: url($path_to_icons + 'alert-circle-48.svg') no-repeat 50% 50%;
      min-width: 48px;
      min-height: 48px;
    }
  }

  &-close {
    -webkit-mask: url($path_to_icons + 'close.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-ultra-logo {
    -webkit-mask: url($path_to_icons + 'ultra-logo.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 23px;

    &-lg {
      -webkit-mask: url($path_to_icons + 'ultra-logo-lg.svg') no-repeat 50% 50%;
      min-width: 30px;
      min-height: 31px;
    }

    &-32 {
      -webkit-mask: url($path_to_icons + 'ultra-logo-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-percent {
    -webkit-mask: url($path_to_icons + 'percent.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-grid {
    -webkit-mask: url($path_to_icons + 'grid.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'grid-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-xsm {
      -webkit-mask: url($path_to_icons + 'grid-xsm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }
  }

  &-official-full-2-16 {
    -webkit-mask: url($path_to_icons + 'official-full-2-16.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-os-entity {
    -webkit-mask: url($path_to_icons + 'os-entity.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
    &-xsm {
      -webkit-mask: url($path_to_icons + 'os-entity-xsm.svg') no-repeat 50% 50%;
      min-width: 14px;
      min-height: 14px;
    }
  }

  &-arrow-sort {
    -webkit-mask: url($path_to_icons + 'arrow-sort.svg') no-repeat 50% 50%;
    min-width: 10px;
    min-height: 6px;
  }

  &-triangle-alert {
    -webkit-mask: url($path_to_icons + 'triangle-alert.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'triangle-alert-sm.svg') no-repeat 50% 50%;
      min-width: 12px;
      min-height: 12px;
    }

    &-24 {
      -webkit-mask: url($path_to_icons + 'triangle-alert-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-notepad {
    -webkit-mask: url($path_to_icons + 'notepad.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-grid-mode {
    &-16 {
      -webkit-mask: url($path_to_icons + 'grid-mode.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-list-mode {
    &-16 {
      -webkit-mask: url($path_to_icons + 'list-mode.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-gradient-loader {
    &-16 {
      -webkit-mask: url($path_to_icons + 'gradient-loader.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
    &-24 {
      -webkit-mask: url($path_to_icons + 'gradient-loader.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
    &-32 {
      -webkit-mask: url($path_to_icons + 'gradient-loader.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-sort {
    &-16 {
      -webkit-mask: url($path_to_icons + 'sort.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-company {
    -webkit-mask: url($path_to_icons + 'company.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-company-add {
    &-16 {
      -webkit-mask: url($path_to_icons + 'company-add-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-company-ban {
    -webkit-mask: url($path_to_icons + 'company-ban-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 15px;
  }

  &-company-delete {
    -webkit-mask: url($path_to_icons + 'company-delete-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 14px;
  }

  &-credit-card {
    -webkit-mask: url($path_to_icons + 'credit-card.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-text-file {
    -webkit-mask: url($path_to_icons + 'text-file.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-minus {
    -webkit-mask: url($path_to_icons + 'minus.svg') no-repeat 50% 50%;
    min-width: 8px;
    min-height: 2px;

    &-24 {
      -webkit-mask: url($path_to_icons + 'minus-24.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-game-dlc {
    -webkit-mask: url($path_to_icons + 'game-dlc.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-files {
    -webkit-mask: url($path_to_icons + 'files.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-dlc-pack {
    -webkit-mask: url($path_to_icons + 'dlc-pack.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-dlc {
    -webkit-mask: url($path_to_icons + 'dlc.svg') no-repeat 50% 50%;
    min-width: 64px;
    min-height: 64px;
  }

  &-dlc-sm {
    -webkit-mask: url($path_to_icons + 'dlc-sm.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-demo {
    -webkit-mask: url($path_to_icons + 'demo.svg') no-repeat 50% 50%;
    min-width: 38px;
    min-height: 39px;

    &-sm {
      -webkit-mask: url($path_to_icons + 'demo-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-lock {
    -webkit-mask: url($path_to_icons + 'lock.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-token-type-game {
    -webkit-mask: url($path_to_icons + 'token-type-game.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-token-type-demo {
    -webkit-mask: url($path_to_icons + 'token-type-demo.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-token-type-dlc {
    -webkit-mask: url($path_to_icons + 'token-type-dlc.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-token-type-lp {
    -webkit-mask: url($path_to_icons + 'token-type-lp.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-token-type-dserver {
    -webkit-mask: url($path_to_icons + 'token-type-dserver.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-nft {
    -webkit-mask: url($path_to_icons + 'nft.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-nft-type {
    -webkit-mask: url($path_to_icons + 'nft-type.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-uniq-lozenge {
    -webkit-mask: url($path_to_icons + 'uniq-lozenge.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-uniq-collectible {
    -webkit-mask: url($path_to_icons + 'uniq-collectible.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-wallet {
    -webkit-mask: url($path_to_icons + 'wallet.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-arrow-swap {
    -webkit-mask: url($path_to_icons + 'arrow-swap.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-signature {
    -webkit-mask: url($path_to_icons + 'signature.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-signature-md {
    -webkit-mask: url($path_to_icons + 'signature-16.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-sync-arrows {
    -webkit-mask: url($path_to_icons + 'sync-arrows.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-blockchain {
    &-sm {
      -webkit-mask: url($path_to_icons + 'blockchain-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-bell {
    &-sm {
      -webkit-mask: url($path_to_icons + 'bell-sm.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-usd {
    -webkit-mask: url($path_to_icons + 'usd.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-eur {
    -webkit-mask: url($path_to_icons + 'eur.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-aud {
    -webkit-mask: url($path_to_icons + 'aud.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-cad {
    -webkit-mask: url($path_to_icons + 'cad.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-chf {
    -webkit-mask: url($path_to_icons + 'chf.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-gbp {
    -webkit-mask: url($path_to_icons + 'gbp.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-token-asterisk {
    -webkit-mask: url($path_to_icons + 'token-asterisk.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-protected {
    -webkit-mask: url($path_to_icons + 'protected.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-protected-stroke {
    -webkit-mask: url($path_to_icons + 'protected-stroke.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-simplex {
    -webkit-mask: url($path_to_icons + 'simplex.svg') no-repeat 50% 50%;
    min-width: 43px;
    min-height: 43px;
  }

  &-uniq-quantity {
    -webkit-mask: url($path_to_icons + 'uniq-quantity.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-uniq-no-quantity {
    -webkit-mask: url($path_to_icons + 'uniq-no-quantity.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-shopping-trolley-disabled {
    -webkit-mask: url($path_to_icons + 'shopping-trolley-disabled.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-infinity {
    -webkit-mask: url($path_to_icons + 'infinity.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-infinity-bold {
    -webkit-mask: url($path_to_icons + 'infinity-bold.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-uniq-launchpad {
    &-24 {
      -webkit-mask: url($path_to_icons + 'uniq-launchpad.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
    &-32 {
      -webkit-mask: url($path_to_icons + 'uniq-launchpad.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
    &-64 {
      -webkit-mask: url($path_to_icons + 'uniq-launchpad.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-uniq-marketplace {
    &-24 {
      -webkit-mask: url($path_to_icons + 'uniq-marketplace.svg') no-repeat 50% 50%;
      min-width: 24px;
      min-height: 24px;
    }
    &-64 {
      -webkit-mask: url($path_to_icons + 'uniq-marketplace.svg') no-repeat 50% 50%;
      min-width: 64px;
      min-height: 64px;
    }
  }

  &-uniq-narrow {
    &-16 {
      -webkit-mask: url($path_to_icons + 'uniq-narrow.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-uniq-quantity {
    -webkit-mask: url($path_to_icons + 'uniq-quantity.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-uniq-no-quantity {
    -webkit-mask: url($path_to_icons + 'uniq-no-quantity.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-uos-add {
    -webkit-mask: url($path_to_icons + 'uos-add.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-token-type-collectible {
    -webkit-mask: url($path_to_icons + 'token-type-collectible.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;

    &-alone {
      -webkit-mask: url($path_to_icons + 'token-type-collectible-alone.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-alert-octagon {
    -webkit-mask: url($path_to_icons + 'alert_octagon.svg') no-repeat 50% 50%;
    min-width: 48px;
    min-height: 48px;
  }

  &-marketplace {
    -webkit-mask: url($path_to_icons + 'marketplace.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-marketplace-uniq {
    &-32 {
      -webkit-mask: url($path_to_icons + 'marketplace-uniq-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-rocket {
    -webkit-mask: url($path_to_icons + 'rocket.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-list {
    -webkit-mask: url($path_to_icons + 'list.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-user-add {
    -webkit-mask: url($path_to_icons + 'user-add.svg') no-repeat 50% 50%;
    min-width: 32px;
    min-height: 32px;
    &-16 {
      -webkit-mask: url($path_to_icons + 'user-add-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-send {
    -webkit-mask: url($path_to_icons + 'send.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-link {
    -webkit-mask: url($path_to_icons + 'link.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-external-link {
    -webkit-mask: url($path_to_icons + 'external-link.svg') no-repeat 50% 50%;
    min-width: 13px;
    min-height: 12px;
  }

  &-log-out {
    -webkit-mask: url($path_to_icons + 'log-out.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-play-transparent {
    background: url($path_to_icons + 'play-transparent.svg') no-repeat;
    min-width: 60px;
    min-height: 60px;
    transition: background 0.2s ease;

    &:hover {
      background: url($path_to_icons + 'play-transparent-hover.svg') no-repeat;
      transition: background 0.2s ease;
    }
  }

  &-media-play {
    background: url($path_to_icons + 'media-play.svg') no-repeat;
    min-width: 16px;
    min-height: 16px;
    &-48 {
      -webkit-mask: url($path_to_icons + 'media-play-48.svg') no-repeat 50% 50%;
      min-width: 48px;
      min-height: 48px;
    }
  }

  &-factory-single {
    &-32 {
      -webkit-mask: url($path_to_icons + 'factory-single-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }
  &-factory-group {
    &-32 {
      -webkit-mask: url($path_to_icons + 'factory-group-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  // utils -> no add icon def after here
  &.reverse {
    transform: rotate(180deg);
  }

  &.opacity {
    opacity: 0.7;
  }

  &.hover {
    opacity: 0.7;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  // sizes
  &.icon-16x {
    min-width: 16px !important;
    min-height: 16px !important;
    width: 16px !important;
    height: 16px !important;
    mask-size: 16px 16px;
  }

  &.icon-24x {
    min-width: 24px !important;
    min-height: 24px !important;
    width: 24px !important;
    height: 24px !important;
    mask-size: 24px 24px;
  }

  &.icon-32x {
    min-width: 32px !important;
    min-height: 32px !important;
    width: 32px !important;
    height: 32px !important;
    mask-size: 32px 32px;
  }

  &.icon-64x {
    min-width: 64px !important;
    min-height: 64px !important;
    width: 64px !important;
    height: 64px !important;
    mask-size: 64px 64px;
  }

  &.icon-48x {
    min-width: 48px !important;
    min-height: 48px !important;
    width: 48px !important;
    height: 48px !important;
    mask-size: 48px 48px;
  }

  &.icon-112x {
    min-width: 112px !important;
    min-height: 112px !important;
    width: 112px !important;
    height: 112px !important;
    mask-size: 112px 112px;
  }

  // colors
  &.icon-color-ultra-purple-light {
    background-color: $ultra-purple-light;
  }

  &.icon-color-white {
    background-color: white;
  }

  &.icon-color-red-light {
    background-color: $red-light !important;
  }

  &-collectible {
    &-sm {
      background: url($path_to_icons + 'collectible.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }

    &-24 {
      -webkit-mask: url($path_to_icons + 'collectible-24.svg') no-repeat 50% 50%;
      mask-size: 24px 24px;
      min-width: 24px;
      min-height: 24px;
    }

    &-32 {
      background: url($path_to_icons + 'collectible-32.svg') no-repeat 50% 50%;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-ornament {
    background: url($path_to_icons + 'ornament.svg') no-repeat 50% 50%;
    min-width: 36px;
    min-height: 36px;
  }

  &-lock {
    &-24 {
      -webkit-mask: url($path_to_icons + 'lock-24.svg') no-repeat 50% 50%;
      mask-size: 24px 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-open-lock {
    &-24 {
      -webkit-mask: url($path_to_icons + 'open-lock-24.svg') no-repeat 50% 50%;
      mask-size: 24px 24px;
      min-width: 24px;
      min-height: 24px;
      &-green {
        background: url($path_to_icons + 'open-lock-24-green.svg') no-repeat 50% 50%;
        min-width: 24px;
        min-height: 24px;
      }
    }
  }

  &-x-circle {
    &-24 {
      -webkit-mask: url($path_to_icons + 'x-circle-24.svg') no-repeat 50% 50%;
      mask-size: 24px 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-filter {
    &-16 {
      -webkit-mask: url($path_to_icons + 'filter-16.svg') no-repeat 50% 50%;
      min-width: 16px;
      min-height: 16px;
    }
  }
}
